.cardhome {
  width: 50%;
}


.colorbtn {
  color: #e4e4e4;
  background-color: #11388a !important;
}

.colorbtn:hover {
  color: white !important;
}

.colorh3 {
  color: #1f4591;
}

.colorh3sub {
  color: #4e4e4e;
}

/* ------------------------------------ */
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}


a {
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
}

/* ------------------------------------ */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-table100 {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.wrap-table100 {
  width: 100%;
}

.list-table table {
  border-spacing: 1;
  border-collapse: collapse;
  background: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.list-table table * {
  position: relative;
}

.list-table table td,
.list-table table th {
  padding-left: 8px;
}

.list-table table thead tr {
  height: 60px;
  background: #1f4591;
}

.list-table table tbody tr {
  height: 50px;
}

.list-table table tbody tr:last-child {
  border: 0;
}

.list-table table td,
.list-table table th {
  text-align: left;
}

.list-table table td.l,
.list-table table th.l {
  text-align: right;
}

.list-table table td.c,
.list-table table th.c {
  text-align: center;
}

.list-table table td.r,
.list-table table th.r {
  text-align: center;
}


.table100-head th {
  font-size: 18px;
  color: #fff;
  line-height: 1.2;
}

.list-table tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}

.list-table tbody tr {
  font-size: 15px;
  color: #808080;
  line-height: 1.2;
}

.list-table tbody tr:hover {
  color: #555555;
  background-color: #f5f5f5;
  cursor: pointer;
}

.column1 {
  width: 140px;
  padding-left: 40px;
}

.column2 {
  width: 260px;
}

.column3 {
  width: 260px;
}

.column4 {
  width: 110px;
  text-align: center;
}

.column5 {
  width: 170px;
  text-align: center;
}

.column6 {
  width: 110px;
  text-align: center;
}


@media screen and (max-width: 992px) {
  .list-table table {
    display: block;
  }

  .list-table table>*,
  .list-table table tr,
  .list-table table td,
  .list-table table th {
    display: block;
  }

  .list-table table thead {
    display: none;
  }

  .list-table table tbody tr {
    height: auto;
    padding: 37px 0;
  }

  .list-table table tbody tr td {
    padding-left: 40% !important;
    margin-bottom: 24px;
  }

  .list-table table tbody tr td:last-child {
    margin-bottom: 0;
  }

  .list-table table tbody tr td:before {
    font-size: 14px;
    color: #292929;
    line-height: 1.2;
    position: absolute;
    width: 40%;
    left: 30px;
    top: 0;
  }

  .list-table table tbody tr td:nth-child(1):before {
    content: "Código";
  }

  .list-table table tbody tr td:nth-child(2):before {
    content: "Nombre";
  }

  .list-table table tbody tr td:nth-child(3):before {
    content: "Correo E.";
  }

  .list-table table tbody tr td:nth-child(4):before {
    content: "Ult. Visita";
  }

  .list-table table tbody tr td:nth-child(5):before {
    content: "Ver Más";
  }

  .list-table table tbody tr td:nth-child(6):before {
    content: "Editar";
  }

  .column4,
  .column5,
  .column6 {
    text-align: left !important;
    justify-items: left !important;
    display: flex !important;
  }

  .column4,
  .column5,
  .column6,
  .column1,
  .column2,
  .column3 {
    width: 100%;
  }

  .list-table tbody tr {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .container-table100 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.table-spinner-container {
  position: sticky;
  top: 1%;
  left: 50%;
  z-index: 1;
  text-align: center;
  height: 1px;

  .spinner-border {
    margin-top: 78px;
  }
}