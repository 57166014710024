.login-card{
    min-height: 600px;
    border-radius: 1rem;

    .left-image {
        border-radius: 1rem 0 0 1rem
    }

    .copyright{
        position: absolute;
        bottom: 3px;
        left: 9%;
    }

}